export const getStudentsFromGroup = async (
    courseName: string,
    groupName: string
) => {
    const apiUrl = "https://centarnit.onrender.com";
    const res = await fetch(
        `${apiUrl}/students/?course_name=${String(
            courseName
        )}&group_name=${String(groupName)}`,
        {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        }
    );
    const usernames = await res.json();
    const students = [];
    for (const username of usernames) {
        const res = await fetch(`${apiUrl}/users/?username=${username}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        });
        const data = await res.json();
        students.push(data);
    }
    // return students[0];
    return { usernames, students };
};
