import { Menu } from "./Navbar";
import { Dashboard } from "./Dashboard/Dashboard";
import { Students } from "./Students/Students";
import { Courses } from "./Courses/Courses";
import { CourseFull } from "./Courses/CourseFull";
import { Group } from "./Courses/Groups/Group";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { useState } from "react";
import { LoginForm } from "./Login/Login";
import { Notify } from "./Notify/Notify";

export const App = () => {
    const [login, setLogin] = useState<boolean>(false);
    return (
        <BrowserRouter>
            {!login && !sessionStorage.getItem("token") ? (
                <LoginForm setLogin={setLogin} />
            ) : (
                <div style={{ display: "flex" }}>
                    <Menu />
                    <Routes>
                        <Route path="/" element={<Dashboard />} />
                        <Route path="/students" element={<Students />} />
                        <Route path="/notify" element={<Notify />} />
                        <Route path="/courses" element={<Outlet />}>
                            <Route index element={<Courses />} />
                            <Route path=":courseId" element={<CourseFull />} />
                            <Route path="group" element={<Group />} />
                        </Route>
                    </Routes>
                </div>
            )}
        </BrowserRouter>
    );
};
