export const CreateUser = async (
    username: string,
    password: string,
    full_name: string,
    github: string,
    email: string,
    phone_number: string,
    job: string
) => {
    const apiUrl = "https://centarnit.onrender.com";
    await fetch(`${apiUrl}/users/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify({
            username: username,
            password: password,
            full_name: full_name,
            github: github,
            email: email,
            phone_number: phone_number,
            job: job,
            role: "student",
            status: "string",
            is_active: "string",
            avatar: "string",
            languages: [],
            approved_at: "string",
            rejected_at: "string",
            rejected_counter: 0,
            courses: [],
        }),
    })
        // TODO: Remove this block once the page to view users who have applied is created.

        .then((_) => {
            fetch(`${apiUrl}/admin/activate/${username}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                },
            });
        })
        .catch((err) => {
            console.log(err);
        });
};
