export const Dashboard = () => {
    return (
        <div className="dashboard">
            <div className="info">
                <div className="item revenue">
                    <h6>Revenue</h6>
                    <h1>$950.031</h1>
                </div>
                <div className="item expenses">
                    <h6>Expenses</h6>
                    <h1>$246.034</h1>
                </div>
                <div className="item people">
                    <div>
                        <h6>People</h6>
                        <h1>150</h1>
                    </div>
                </div>
            </div>
            <div className="main">
                <a className="course-main" href="/courses/itcamp">
                    <h1>IT CAMP 2023</h1>
                </a>
                <div className="courses">
                    <a className="python" href="/courses/python">
                        <h1>Python</h1>
                    </a>
                    <a className="ui" href="/courses/design">
                        <h1>UI/UX</h1>
                    </a>
                    <a className="robotics" href="/courses/robotics">
                        <h1>Robotics</h1>
                    </a>
                </div>
            </div>
        </div>
    );
};
