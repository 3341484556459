import { Card } from "../../Students/StudentUtils/Card";
import { useLocation } from "react-router-dom";
import { getStudentsFromGroup } from "../../../api/hooks/getStudentsFromGroup";
import { useEffect, useState } from "react";
import { ActionModal, QuickActionModal } from "./GroupUtils/ActionModal";
import { StudentsTable } from "./GroupUtils/StudentsTable";
import { IconApps } from "@tabler/icons-react";

export const Group = () => {
    const location = useLocation();
    const [students, setStudents] = useState<any>({
        res: [],
        data: [],
    });
    const [add, setAdd] = useState<boolean>(false);
    const [remove, setRemove] = useState<boolean>(false);
    const [quick, setQuick] = useState<boolean>(false);

    useEffect(() => {
        getStudentsFromGroup(
            location.state.data.name,
            location.state.group.name
        ).then((data) =>
            setStudents({
                res: data.usernames,
                users: data.students[0],
            })
        );
    }, []);

    return (
        <div className="group">
            <ActionModal
                key={"POST"}
                data={location.state.data}
                action={"POST"}
                course={location.state.data.name}
                group={location.state.group.name}
                cycles={location.state.data.cycles}
                open={add}
                setOpen={setAdd}
            />
            <ActionModal
                key={"DELETE"}
                data={location.state.data}
                action={"DELETE"}
                course={location.state.data.name}
                group={location.state.group.name}
                cycles={location.state.data.cycles}
                open={remove}
                setOpen={setRemove}
            />
            <QuickActionModal
                group={location.state.group}
                course={location.state.data}
                open={quick}
                setOpen={setQuick}
                numbers={students}
            />
            <div className="group-data">
                <div className="group-info">
                    <h6>{location.state.data.duration}</h6>
                    <h5>Cycle: {location.state.group.current_cycle}</h5>
                    <h1>{location.state.group.name}</h1>
                    <h2>Instructor: {location.state.group.instructor}</h2>
                </div>
                <div className="group-actions">
                    <button className="add" onClick={() => setAdd(true)}>
                        +
                    </button>
                    <button className="remove" onClick={() => setRemove(true)}>
                        -
                    </button>
                    <button onClick={() => setQuick(true)}>
                        <IconApps />
                    </button>
                </div>
            </div>
            <StudentsTable data={students} />
            {/* <div className="students-cards">
                {students.users.length > 0 &&
                    students.map((student: any) => {
                        return <Card data={student} key={student.full_name} />;
                    })}
            </div> */}
        </div>
    );
};
