export const getCourse = async (courseId: string) => {
    const apiUrl = "https://centarnit.onrender.com";
    const res = await fetch(`${apiUrl}/courses/${courseId}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
    });
    return await res.json();
};
