import axios from "axios";

export const sendNotification = async (numbers: string, message: string) => {
    const apiUrl = "https://centarnit.onrender.com";

    axios
        .post(`${apiUrl}/notify`, {
            headers: {
                accept: "application/json",
                "Content-Type": "application/json",
            },
            numbers: Array.from(numbers.split("\n")),
            message: message,
        })
        .then((res) => {
            console.log(res);
        })
        .catch((err) => {
            console.log(err);
        });
};
