import { Button, Modal, Text, TextInput } from "@mantine/core";
import { useState } from "react";
import { getUserAction } from "../../../../api/hooks/getUserAction";
import { moveToNextCycle } from "../../../../api/hooks/moveToNextCycle";
import { useNavigate } from "react-router-dom";

export const ActionModal = (props: {
    data: any;
    action: string;
    course: string;
    group: string;
    cycles: number;
    open: boolean;
    setOpen: any;
}) => {
    const [student, setStudent] = useState<any>();

    const handleAction = async () => {
        await getUserAction(
            student,
            props.course,
            props.group,
            props.action === "DELETE" ? "DELTE" : "POST"
        ).then((res) => {
            console.log(res);
        });
        window.location.reload();
    };

    return (
        <Modal
            opened={props.open}
            onClose={() => props.setOpen(false)}
            title={props.action === "DELETE" ? "Remove student" : "Add student"}
            size="md"
            overlayOpacity={0.35}
            overlayBlur={0.1}
        >
            <div className="modal">
                <TextInput
                    label="Student name"
                    placeholder="Student name"
                    required
                    name="student_name"
                    value={student}
                    onChange={(event) => setStudent(event.currentTarget.value)}
                    mb="md"
                    mt="lg"
                />
                <Button onClick={handleAction} color={"blue"}>
                    Submit
                </Button>
            </div>
        </Modal>
    );
};

export const QuickActionModal = (props: {
    setOpen: any;
    course: string;
    group: string;
    open: boolean;
    numbers: any;
}) => {
    const navigate = useNavigate();
    return (
        <Modal
            opened={props.open}
            onClose={() => props.setOpen(false)}
            size="md"
            overlayOpacity={0.35}
            overlayBlur={0.1}
            title="Quick Actions"
        >
            <div className="modal">
                <Button onClick={() => {}} color={"blue"} mr="md" mt="md">
                    Not Paid Query
                </Button>
                <Button
                    color={"red"}
                    onClick={() => {
                        moveToNextCycle(props.course, props.group);
                        window.location.reload();
                    }}
                >
                    Move to next cycle
                </Button>
                <Button
                    onClick={() => {
                        navigate("/notify", {
                            replace: true,
                            state: { group: props.numbers },
                        });
                    }}
                    color={"yellow"}
                    mr="md"
                    mt="md"
                >
                    Notify
                </Button>
            </div>
        </Modal>
    );
};
