import "../../../utils/styles/pages/students.scss";

export const Loader = () => {
    return (
        <div className="loader">
            <div className="inner one"></div>
            <div className="inner two"></div>
            <div className="inner three"></div>
        </div>
    );
};
