import "../utils/styles/menu.scss";
import {
    IconHome2,
    IconUser,
    IconBook,
    IconNews,
    IconDeviceDesktop,
    IconApps,
} from "@tabler/icons-react";
import { useViewportSize } from "@mantine/hooks";

const data = [
    { icon: <IconHome2 className="icon" />, title: "Home", link: "/" },
    {
        icon: <IconUser className="icon" />,
        title: "Students",
        link: "/students",
    },
    { icon: <IconBook className="icon" />, title: "Courses", link: "/courses" },
    { icon: <IconNews className="icon" />, title: "News", link: "/news" },
    {
        icon: <IconDeviceDesktop className="icon" />,
        title: "Projects",
        link: "/projects",
    },
    {
        icon: <IconApps className="icon" />,
        title: "Utilites",
        link: "/utilities",
    },
];

const SideBar = () => {
    return (
        <div className="sidebar">
            <div className="title">
                <h1>CentarNIT</h1>
            </div>
            <div className="items">
                {data.map((item, index) => (
                    <a className="item" key={index} href={item.link}>
                        {item.icon}
                        <h1>{item.title}</h1>
                    </a>
                ))}
            </div>
        </div>
    );
};

const MobileMenu = () => {
    return (
        <>
            <div className="menu">
                <div className="menu-container">
                    {data.map((item, index) => (
                        <div className="menu-item" key={index}>
                            {item.icon}
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export const Menu = () => {
    const { width } = useViewportSize();
    return <>{width < 768 ? <MobileMenu /> : <SideBar />}</>;
};
