import { Button, Modal, TextInput } from "@mantine/core";
import { useState } from "react";
import { CreateUser } from "../../../api/hooks/useCreateUser";

export const AddStudentModal = (props: { open: boolean; setOpen: any }) => {
    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [full_name, setFullName] = useState<string>("");
    const [github, setGithub] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [phone_number, setPhoneNumber] = useState<string>("");
    const [job, setJob] = useState<string>("");

    const fields = [
        {
            state: username,
            setState: setUsername,
            placeholder: "Username: ",
            label: "Username",
        },
        {
            state: password,
            setState: setPassword,
            placeholder: "Password: ",
            label: "Password",
        },
        {
            state: full_name,
            setState: setFullName,
            placeholder: "Full name: ",
            label: "Full name",
        },
        {
            state: github,
            setState: setGithub,
            placeholder: "Github: ",
            label: "Github",
        },
        {
            state: email,
            setState: setEmail,
            placeholder: "Email: ",
            label: "Email",
        },
        {
            state: phone_number,
            setState: setPhoneNumber,
            placeholder: "Phone number: ",
            label: "Phone number",
        },
        {
            state: job,
            setState: setJob,
            placeholder: "Job: ",
            label: "Job",
        },
    ];

    const handleAddStudent = async () => {
        await CreateUser(
            username,
            password,
            full_name,
            github,
            email,
            phone_number,
            job
        );
        // window.location.reload();
    };

    return (
        <Modal
            opened={props.open}
            onClose={() => props.setOpen(false)}
            title={"Add student"}
            size="md"
            overlayOpacity={0.35}
            overlayBlur={0.1}
        >
            {fields.map((field) => {
                return (
                    <TextInput
                        label={field.label}
                        placeholder={field.placeholder}
                        required
                        name={field.state}
                        value={field.state}
                        onChange={(event) =>
                            field.setState(event.currentTarget.value)
                        }
                        mt="sm"
                    />
                );
            })}
            <Button onClick={handleAddStudent} color={"blue"} mr="md" mt="md">
                Submit
            </Button>
            <Button onClick={() => props.setOpen(false)} color={"red"}>
                Close
            </Button>
        </Modal>
    );
};
