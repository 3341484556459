import { IconPencil, IconX } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import "../../utils/styles/pages/course.scss";

export const CourseRow = (props: { cycle: number; data: any }) => {
    const groups = props.data.groups.filter(
        (group: any) => group.current_cycle === props.cycle
    );
    const navigate = useNavigate();

    return (
        <div className="courses-row">
            <h1>Cycle: {props.cycle}</h1>
            <div className="groups">
                {groups.map((group: any) => {
                    return (
                        <a
                            onClick={() => {
                                navigate("/courses/group", {
                                    state: { group: group, data: props.data },
                                });
                            }}
                            key={group.name}
                        >
                            <h6>{group.name}</h6>
                            <h1>{group.instructor}</h1>
                            <div className="actions">
                                <button className="edit">
                                    <IconPencil />
                                </button>
                                <button className="remove">
                                    <IconX />
                                </button>
                            </div>
                        </a>
                    );
                })}
            </div>
        </div>
    );
};

export default CourseRow;
