import ReactDOM from "react-dom/client";
import { App } from "./components/App";
import { ModalsProvider } from "@mantine/modals";
import { MantineProvider } from "@mantine/core";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <MantineProvider>
        <ModalsProvider>
            <App />
        </ModalsProvider>
    </MantineProvider>
);
