import { Card } from "./StudentUtils/Card";
import "../../utils/styles/pages/students.scss";
import axios from "axios";
import { useEffect, useState } from "react";
import { Loader } from "./StudentUtils/Loader";
import { AddStudentModal } from "./StudentUtils/AddStudentModal";

export const Students = () => {
    const [students, setStudents] = useState([]);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    useEffect(() => {
        axios
            .get("https://centarnit.onrender.com/users")
            .then((res) => {
                setLoading(false);
                setStudents(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    if (loading) {
        return <Loader />;
    }
    return (
        <>
            <AddStudentModal open={open} setOpen={setOpen} />
            {students.length > 0 ? (
                <div className="students">
                    <div className="search">
                        <input
                            type="text"
                            placeholder="Search"
                            id="search"
                            onChange={(e) => {
                                setSearch(e.target.value);
                            }}
                        />
                        <button onClick={() => setOpen(true)}>Add</button>
                    </div>
                    <div className="students-cards">
                        {students
                            .filter((student: any) => {
                                if (search === "") {
                                    return student;
                                } else if (
                                    student.full_name
                                        .toLowerCase()
                                        .includes(search.toLowerCase())
                                ) {
                                    return student;
                                }
                            })
                            .map((student: any) => {
                                return (
                                    <Card
                                        data={student}
                                        key={student.username}
                                    />
                                );
                            })}
                    </div>
                </div>
            ) : (
                <Loader />
            )}
        </>
    );
};
