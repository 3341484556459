import { Table } from "@mantine/core";
import { useState } from "react";

export const StudentsTable = (props: { data: any }) => {
    console.log(props.data.users);
    const [selected, setSelected] = useState<any>([]);
    const rows =
        props.data.users &&
        props.data.users.map((element: any) => (
            <tr key={element.username} onClick={() => setSelected(element)}>
                <td>{element.email}</td>
                <td>{element.job}</td>
                <td>{element.is_active}</td>
            </tr>
        ));
    return (
        <Table highlightOnHover mt={100} verticalSpacing="xl" fontSize="md">
            <thead>
                <tr>
                    <th>Full Name</th>
                    <th>Email</th>
                    <th>Cycles</th>
                    <th>Phone</th>
                </tr>
            </thead>
            <tbody>{rows}</tbody>
        </Table>
    );
};
