import axios from "axios";
import { useState } from "react";
import "../../utils/styles/pages/login.scss";

export const LoginForm = (props: { setLogin: any }) => {
    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");

    return (
        <div className="login">
            <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
            />
            <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <button
                onClick={() => {
                    axios
                        .post(
                            "https://centarnit.onrender.com/auth/login",
                            new URLSearchParams({
                                grant_type: "",
                                username: "admin",
                                password: "admin",
                                scope: "",
                                client_id: "",
                                client_secret: "",
                            }),
                            {
                                headers: {
                                    accept: "application/json",
                                },
                            }
                        )
                        .then((res: any) => {
                            sessionStorage.setItem(
                                "token",
                                res.data.access_token
                            );
                            props.setLogin(true);
                            console.log(sessionStorage.getItem("token"));
                        });
                }}
            >
                Login
            </button>
        </div>
    );
};
