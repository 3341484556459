export const moveToNextCycle = async (
    courseName: string,
    groupName: string
) => {
    const apiUrl = "https://centarnit.onrender.com";
    const res = await fetch(`${apiUrl}/groups/move_to_next_cycle`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify({
            group_name: groupName,
            course_name: courseName,
        }),
    });
    return await res.json();
};
