import "../../utils/styles/pages/dashboard.scss";

export const Courses = () => {
    return (
        <div className="dashboard">
            <div className="main">
                <a className="course-main" href="/courses/itcamp">
                    <h1>IT CAMP 2023</h1>
                </a>
                <div className="courses">
                    <a className="python" href="/courses/python">
                        <h1>Python</h1>
                    </a>
                    <a className="ui" href="/courses/design">
                        <h1>UI/UX</h1>
                    </a>
                    <a className="robotics" href="/courses/robotics">
                        <h1>Robotics</h1>
                    </a>
                </div>
            </div>
        </div>
    );
};
