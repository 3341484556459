import axios from "axios";

export const getUserAction = async (
    student_name: string,
    course_name: string,
    group_name: string,
    action: string
) => {
    const apiUrl = "https://centarnit.onrender.com";
    action === "POST"
        ? axios
              .post(
                  `${apiUrl}/students/add`,
                  {
                      student_name: student_name,
                      group_name: group_name,
                      course_name: course_name,
                  },
                  {
                      headers: {
                          accept: "application/json",
                          Authorization: `Bearer ${sessionStorage.getItem(
                              "token"
                          )}`,
                          "Content-Type": "application/json",
                      },
                  }
              )
              .then((res) => {
                  console.log(res);
              })
              .catch((err) => {
                  console.log(err);
              })
        : axios
              .delete(`${apiUrl}/students/remove`, {
                  headers: {
                      accept: "application/json",
                      Authorization: `Bearer ${sessionStorage.getItem(
                          "token"
                      )}`,
                      "Content-Type": "application/json",
                  },
                  data: {
                      student_name: student_name,
                      group_name: group_name,
                      course_name: course_name,
                  },
              })
              .then((res) => {
                  console.log(res);
              })
              .catch((err) => {
                  console.log(err);
              });
};
