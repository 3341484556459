import { getCourse } from "../../api/hooks/getCourse";
import { useState, useEffect } from "react";
import CourseRow from "./CourseRow";

interface Course {
    name: string;
    description: string;
    topic: string;
    duration: number;
    plan: string;
    instructors: Array<string>;
    groups: Array<object>;
    timeline: Array<object>;
    cycles: number;
}

export const CourseFull = () => {
    const [course, setCourse] = useState<Course>();
    const courseId = window.location.pathname.split("/")[2];

    useEffect(() => {
        getCourse(courseId).then((data) => setCourse(data));
    }, []);

    return (
        <div id="course">
            {course &&
                Array.from({ length: course.cycles }).map((_, index) => {
                    return <CourseRow cycle={index + 1} data={course} />;
                })}
        </div>
    );
};
