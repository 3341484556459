import { useEffect, useState } from "react";
import "../../utils/styles/pages/notify.scss";
import { sendNotification } from "../../api/hooks/sendNotification";
import { useLocation } from "react-router-dom";

export const Notify = () => {
    const [message, setMessage] = useState<string>("");
    const location = useLocation();
    const [numbers, setNumbers] = useState<string>(
        location.state && location.state.group.users
            ? location.state.group.users
                  .map((user: any) => user.phone_number)
                  .join("\n")
            : ""
    );

    return (
        <div className="notify">
            <div className="notify-container">
                <form>
                    <textarea
                        placeholder="Numbers"
                        value={numbers}
                        onChange={(e) => setNumbers(e.target.value)}
                    />
                    <textarea
                        placeholder="Message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            sendNotification(numbers, message);
                        }}
                    >
                        Send
                    </button>
                </form>
            </div>
        </div>
    );
};
