import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "../../../utils/styles/pages/students.scss";
import { Avatar } from "@mantine/core";
import { IconEdit, IconPencil, IconX } from "@tabler/icons-react";

const RingProgress = (props: { data: any }) => {
    return (
        <div className="rings">
            {props.data.map((item: any) => {
                return (
                    <div style={{ width: 100, height: 30 }} key={item.language}>
                        <CircularProgressbarWithChildren
                            value={item.percentage}
                            styles={{
                                path: {
                                    stroke: item.color,
                                    transform: "scale(0.7)",
                                    transformOrigin: "center center",
                                },
                                trail: {
                                    transform: "scale(0.7)",
                                    transformOrigin: "center center",
                                    stroke: "rgb(242, 244, 245)",
                                },
                                text: {
                                    fill: item.color,
                                    fontSize: "13px",
                                    fontFamily: "Helvetica",
                                },
                            }}
                        >
                            <h1 style={{ color: item.color, fontSize: "6px" }}>
                                {item.language}
                            </h1>
                            <h1 style={{ color: item.color, fontSize: "10px" }}>
                                {item.percentage}%
                            </h1>
                        </CircularProgressbarWithChildren>
                    </div>
                );
            })}
        </div>
    );
};

export const Card = (props: { data: any }) => {
    return (
        <div className="card">
            <div className="icon">
                {props.data.avatar !== "" ? (
                    <img src={props.data.avatar} alt="icon" />
                ) : (
                    <Avatar
                        color="blue"
                        size="xl"
                        radius="xl"
                        style={{ backgroundColor: props.data.color }}
                    />
                )}
            </div>
            <h1>{props.data.full_name}</h1>
            <h1>{props.data.job}</h1>

            <div className="info">
                <RingProgress data={props.data.languages} />
            </div>
            <div className="actions">
                <button className="edit">
                    <IconPencil />
                </button>
                <button className="delete">
                    <IconX />
                </button>
            </div>
        </div>
    );
};
